import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout.js"
import Seo from "../components/seo.js"


const IndexPage = ({ data }) => {
  return (
        <Layout>
        <Seo
          pagetitle="会社案内"
          pagedesc="シナゲート株式会社は、2006年5月に東京で設立しました。まずはお客様のご要望をじっくりお伺いすること、お客様の目的に合わせた最適なプランをご提案すること、目的を達成するために十分な技術をもってWEBサイトを制作すること、を念頭に仕事に取り組んでおります。"
        />
      <div>
        <div className="mainTtlBg">
          <div className="mainTtlArea container">
            <h1>会社案内<span>COMPANY</span></h1>
          </div>{/* /mainphArea */}
        </div>{/* /mainphAreabg */}
      {/* パン屑 start */}
      <div className="breadArea">
        <ul className="breadLt container clearfix" itemScope itemType="https://schema.org/BreadcrumbList">
          <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
            <Link to="https://jamstack-sg.com/" itemProp="item"><span itemProp="name">ヘッドレスCMS特設サイト</span></Link>
            <meta itemProp="position" content={1} />
          </li>
          <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
            <Link to="https://jamstack-sg.com/company/" itemProp="item"><span itemProp="name">会社案内</span></Link>
            <meta itemProp="position" content={2} />
          </li>
        </ul>
      </div>
      {/* パン屑 end */}
        <section>
          <div className="containerS">
            <p className="pb40">シナゲート株式会社は、2006年5月に東京で設立しました。まずはお客様のご要望をじっくりお伺いすること、お客様の目的に合わせた最適なプランをご提案すること、目的を達成するために十分な技術をもってWEBサイトを制作すること、を念頭に仕事に取り組んでおります。</p>
            <div className="pb80">
              <table className="detailTb">
                <tbody><tr>
                    <th>商号</th>
                    <td>シナゲート株式会社</td>
                  </tr>
                  <tr>
                    <th>設立</th>
                    <td>2006年5月</td>
                  </tr>
                  <tr>
                    <th>資本金</th>
                    <td>6,000,000円</td>
                  </tr>
                  <tr>
                    <th>役員</th>
                    <td>代表取締役　梅原 健 （うめはら けん）<br />
                      取締役　飯田 雄一<br />
                      取締役　鳥羽 健二郎</td>
                  </tr>
                  <tr>
                    <th>取引銀行</th>
                    <td>みずほ銀行　神田駅前支店<br />
                      朝日信用金庫　豊島町支店</td>
                  </tr>
                  <tr>
                    <th>所在地</th>
                    <td>〒101-0031<br />
                      東京都千代田区東神田3-6-6<br />
                      メビュウス高島 2F</td>
                  </tr>
                  <tr>
                    <th>電話</th>
                    <td>（代表）03-6413-7735</td>
                  </tr>
                  <tr>
                    <th>FAX</th>
                    <td>（代表）03-6413-7736</td>
                  </tr>
                  <tr>
                    <th>WEB</th>
                    <td><Link to={`https://www.synergate.co.jp/`}>https://www.synergate.co.jp/</Link></td>
                  </tr>
                  <tr>
                    <th>事業内容</th>
                    <td>
                      <ul className="dotLi">
                        <li>Webシステム開発・Webサイト制作事業</li>
                        <li>システム要件定義／設計／開発</li>
                        <li>コンサルティング／プランニング</li>
                        <li>Webサイト分析</li>
                        <li>SEO・SEM</li>
                        <li>Webコンテンツ制作</li>
                        <li>Webサイトマネジメント</li>
                        <li>Webアプリケーション開発</li>
                        <li>サーバ管理</li>
                        <li>サポート業務</li>
                      </ul>
                    </td>
                  </tr>
                </tbody></table>
            </div>
          </div>{/* /containerS */}
        </section>
        <section>
          <div className="pt20 pb50">
            <div className="containerS">
              <h2 className="h2Ttl">交通・アクセス</h2>
              <p className="alct pb40">東京都千代田区東神田3-6-6 メビュウス高島 2F<br />最寄り駅：JR総武線　浅草橋駅、都営浅草線　浅草橋駅</p>
            </div>
            <iframe loading="lazy" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.170173053606!2d139.78007411557465!3d35.69742973669023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188eae0ee87191%3A0xa860c02d39acdd5a!2z44K344OK44Ky44O844OI5qCq5byP5Lya56S-!5e0!3m2!1sja!2sjp!4v1527818618794" width="100%" height="400" frameborder="0" allowfullscreen></iframe>
          </div>
        </section>
      </div>

        </Layout>
  )
}



export default IndexPage
